@if (searchResults.loadState.status !== 'error') {
  @if (searchResults.response?.pagingId) {
    <div class="flex items-center page-container my-4 p-[0!important]">
      <app-kz-desktop-pagination
        [isLoading]="searchResults.loadState.status === 'loading'"
        [currentPage]="searchResults.response?.pageNumber ?? 1"
        [pagesCount]="searchResults.response?.pageCount ?? 1"
        [totalCount]="searchResults.response?.totalCount"
        [selectedRawCount]="service.pageRowCount"
        (selectPage)="
          onPageSelect({
            pageNumber: $event,
            pagingId: searchResults.response?.pagingId,
            pageRowCount: service.pageRowCount(),
            sortByField: columnSortOrders?.sortByField,
            sortOrder: columnSortOrders?.sortOrder
          })
        "
        (selectPageRawCount)="
          onPerPageChange({
            pageNumber: 1,
            pagingId: searchResults.response?.pagingId,
            pageRowCount: $event,
            sortByField: columnSortOrders?.sortByField,
            sortOrder: columnSortOrders?.sortOrder
          })
        "
      />
    </div>
  }

  @if (searchResults.response?.pagingId) {
    <div class="page-container p-[0!important]">
      <div class="w-full px-4 pb-2 pt-4 justify-between flex flex-row text-xs">
        <div class="flex flex-row gap-3 mx-2">
          <div class="flex gap-2 items-center">
            <div class="rectangle free_rale"></div>
            <span>{{ 'Available Rooms' | translate }}</span>
          </div>
          <div class="flex gap-2 items-center">
            <div class="rectangle on_request"></div>
            <span>{{ 'Upon request' | translate }}</span>
          </div>
          <div class="flex gap-2 items-center">
            <div class="rectangle stop_sale"></div>
            <span>{{ 'Suspended sales' | translate }}</span>
          </div>
        </div>
        <div class="flex flex-row gap-2 mx-2">
          <div class="flex gap-1 items-center">
            <mat-icon class="plane free_sale" svgIcon="hotels-results-plane" />
            <span>{{ 'Places Available' | translate }}</span>
          </div>
          <div class="flex gap-1 items-center">
            <mat-icon class="plane gds" svgIcon="hotels-results-plane" />
            <span>{{ 'GDS' | translate }}</span>
          </div>
          <div class="flex gap-1 items-center">
            <mat-icon class="plane stop_sale" svgIcon="hotels-results-plane" />
            <span>{{ 'Suspended sales' | translate }}</span>
          </div>
          <div class="flex gap-1 items-center">
            <mat-icon class="plane on_request" svgIcon="hotels-results-plane" />
            <span>{{ 'Upon request' | translate }}</span>
          </div>
          <div class="flex gap-1 items-center">
            <mat-icon class="plane few_places" svgIcon="hotels-results-plane" />
            <span>{{ 'Few seats' | translate }}</span>
          </div>
        </div>
      </div>
      <table class="w-full results-table">
        <thead>
          <td width="12%">
            <div class="sort">
              <span> {{ 'Check-in date' | translate }}</span>
              <button
                type="button"
                mat-icon-button
                class="mat-icon-button-small"
                [disableRipple]="true"
                (click)="sortColumn('SortCheckInOut')"
              >
                <mat-icon
                  [svgIcon]="
                    columnSortOrders?.sortByField === 'SortCheckInOut' && columnSortOrders?.sortOrder
                      ? 'sort-' + columnSortOrders?.sortOrder?.toLowerCase()
                      : 'sort'
                  "
                />
              </button>
            </div>
          </td>
          <td>
            <div class="sort">
              <span> {{ 'Airline' | translate }}</span>
              <button
                type="button"
                mat-icon-button
                class="mat-icon-button-small"
                [disableRipple]="true"
                (click)="sortColumn('SortAirline')"
              >
                <mat-icon
                  [svgIcon]="
                    columnSortOrders?.sortByField === 'SortAirline' && columnSortOrders?.sortOrder
                      ? 'sort-' + columnSortOrders?.sortOrder?.toLowerCase()
                      : 'sort'
                  "
                />
              </button>
            </div>
          </td>

          <td>
            <div class="sort">
              {{ 'Hotel name' | translate }}

              <button
                type="button"
                mat-icon-button
                class="mat-icon-button-small"
                [disableRipple]="true"
                (click)="sortColumn('SortHotelName')"
              >
                <mat-icon
                  [svgIcon]="
                    columnSortOrders?.sortByField === 'SortHotelName' && columnSortOrders?.sortOrder
                      ? 'sort-' + columnSortOrders?.sortOrder?.toLowerCase()
                      : 'sort'
                  "
                />
              </button>
            </div>
          </td>
          <td width="12%">
            <div class="sort">
              {{ 'Details' | translate }}

              <button
                type="button"
                mat-icon-button
                class="mat-icon-button-small"
                [disableRipple]="true"
                (click)="sortColumn('SortDetails')"
              >
                <mat-icon
                  [svgIcon]="
                    columnSortOrders?.sortByField === 'SortDetails' && columnSortOrders?.sortOrder
                      ? 'sort-' + columnSortOrders?.sortOrder?.toLowerCase()
                      : 'sort'
                  "
                />
              </button>
            </div>
          </td>
          <td width="18%">
            <div class="sort">
              {{ 'Rooms and capacity' | translate }}
              <button
                type="button"
                mat-icon-button
                class="mat-icon-button-small"
                [disableRipple]="true"
                (click)="sortColumn('SortPlacement')"
              >
                <mat-icon
                  [svgIcon]="
                    columnSortOrders?.sortByField === 'SortPlacement' && columnSortOrders?.sortOrder
                      ? 'sort-' + columnSortOrders?.sortOrder?.toLowerCase()
                      : 'sort'
                  "
                />
              </button>
            </div>
          </td>
          <td width="12%">
            <div class="sort">
              {{ 'Price' | translate }}
              <button
                type="button"
                mat-icon-button
                class="mat-icon-button-small"
                [disableRipple]="true"
                (click)="sortColumn('TotalPrice')"
              >
                <mat-icon
                  [svgIcon]="
                    columnSortOrders?.sortByField === 'TotalPrice' && columnSortOrders?.sortOrder
                      ? 'sort-' + columnSortOrders?.sortOrder?.toLowerCase()
                      : 'sort'
                  "
                />
              </button>
            </div>
          </td>
        </thead>
        <tbody>
          @for (item of searchResults.response?.packages; track item.id; let index = $index) {
            <ng-container [ngTemplateOutlet]="rowTemplateRef" [ngTemplateOutletContext]="{ item, index }" />
          }
        </tbody>
      </table>
    </div>
    <div class="w-full page-container flex items-center justify-center my-2 p-[0!important]">
      <app-kz-desktop-pagination
        [isLoading]="searchResults.loadState.status === 'loading'"
        [currentPage]="searchResults.response?.pageNumber ?? 1"
        [pagesCount]="searchResults.response?.pageCount ?? 1"
        [totalCount]="searchResults.response?.totalCount"
        [selectedRawCount]="service.pageRowCount"
        (selectPage)="
          onPageSelect({
            pageNumber: $event,
            pagingId: searchResults.response?.pagingId,
            pageRowCount: service.pageRowCount(),
            sortByField: columnSortOrders?.sortByField,
            sortOrder: columnSortOrders?.sortOrder
          })
        "
        (selectPageRawCount)="
          onPerPageChange({
            pageNumber: 1,
            pagingId: searchResults.response?.pagingId,
            pageRowCount: $event,
            sortByField: columnSortOrders?.sortByField,
            sortOrder: columnSortOrders?.sortOrder
          })
        "
      />
    </div>
  }
} @else if (!searchResults.loadState.status.includes('loading')) {
  <div class="page-container mt-4 bg-kz-layout-ground-light h-[30rem] p-8">
    <div class="w-full h-full">
      <app-kz-message-box
        type="error"
        [message]="
          searchResults.loadState.errorMessage
            ? (searchResults.loadState.errorMessage | translate)
            : ('Data not found' | translate)
        "
      />
      <div class="w-full h-full bg-no-results mb-5"></div>
    </div>
  </div>
}

<ng-template
  #rowTemplateRef
  let-item="item"
  let-isChild="isChild"
  let-index="index"
  [typedTemplate]="itemsTypedTemplateToken"
>
  <tr [ngClass]="item.hotel?.availabilityType | snakeCase">
    <td width="12%" [ngClass]="{ 'hide-top-border': isChild, featured: item.subList?.length }">
      <div class="font-medium text-sm">{{ item.hotel?.checkInDate | date }}</div>
      <div class="font-medium text-sm">{{ item.hotel?.checkOutDate | date }}</div>
    </td>
    <td [ngClass]="{ 'hide-top-border': isChild }">
      <div class="flex flex-row items-center w-full">
        <span class="font-medium text-sm">
          {{ item.flight?.departureSegments?.at(0)?.airlineName }}
        </span>
      </div>
      <div class="flex gap-1 flex-col text-sm">
        <div class="flex gap-1">
          <div class="w-[3rem]">{{ item.flight?.departureSegments?.at(0)?.flightCode }}</div>
          <app-kz-flight-class
            [flightClass]="item.flight?.departureSegments?.at(0)?.class"
            [ticketsLeft]="item.flight?.departureTicketsLeft"
            [isBlock]="item.flight?.isBlock"
          />
        </div>
        <div class="flex gap-1 text-sm">
          <div class="w-[3rem]">{{ item.flight?.returnSegments?.at(0)?.flightCode }}</div>
          <app-kz-flight-class
            [flightClass]="item.flight?.returnSegments?.at(0)?.class"
            [ticketsLeft]="item.flight?.returnTicketsLeft"
            [isBlock]="item.flight?.isBlock"
          />
        </div>
      </div>
    </td>
    <td>
      <span [ngClass]="{ invisible: isChild }">
        <div class="flex flex-row gap-1 items-center text-sm justify-between">
          <div class="font-medium w-[70%]" [style.color]="item.hotel?.hotelColor">
            {{ item.hotel?.hotelName }}
          </div>
          <app-kz-stars class="pb-1 w-[29%]" [stars]="item.hotel?.hotelClass ?? 0" />
        </div>
        <div class="text-sm text-kz-grey-400">
          {{ item.hotel?.cityName }},
          {{ item.hotel?.districtName }}
        </div>
        @if (item.subList?.length) {
          <div>
            <button type="button" mat-flat-button color="primary" class="btn-collapse" (click)="onCollapse(index)">
              {{ 'All prices of this hotel' | translate }}
              <span class="collapse-icon" [ngClass]="{ collapsed: collapsed === index }">
                <mat-icon class="icon" svgIcon="chevron" />
              </span>
            </button>
          </div>
        }
      </span>
    </td>
    <td width="12%" #overlayTrigger>
      <div class="text-sm flex">
        {{ item.hotel?.mealPlan }}
        @if (item.hotel?.placementDescription) {
          <button
            type="button"
            class="mat-icon-button-xs"
            mat-icon-button
            dynamicOverlay
            [overlayTrigger]="overlayTrigger"
            [overlayTemplate]="placementDescriptionTemplateRef"
            [overlayWidth]="300"
          >
            <mat-icon svgIcon="info-circle-fill" color="accent" />
          </button>
          <ng-template #placementDescriptionTemplateRef>
            <div class="bg-kz-light-blue-300 rounded-md shadow-lg p-2">{{ item.hotel?.placementDescription }}</div>
          </ng-template>
        }
      </div>
      <div class="text-sm">{{ item.hotel?.nights }} {{ 'Nights' | translate }}</div>
    </td>
    <td width="18%">
      <div class="text-sm font-medium">{{ item.hotel?.roomCategoryName }}/{{ item?.hotel?.placementTypeName }}</div>
      <!-- @note - no need at this moment
      <button mat-icon-button class="matrix-btn">
        <mat-icon class="matrix-icon" svgIcon="hotel-matrix-icon"></mat-icon>
      </button> -->
    </td>
    <td width="12%">
      @if (item.totalPrice) {
        <button type="button" class="stroked-button w-full p-1 text-sm" matRipple (click)="onOpenDetails(item)">
          {{ item.totalPrice | kzPrice: item.priceCurrency }}
        </button>
      }
    </td>
  </tr>

  @if (item.subList?.length) {
    @if (collapsed === index) {
      <tr [@collapse]>
        <td colspan="6" class="p-[0!important] hide-top-border featured">
          <table class="w-full results-table child-table">
            <tbody>
              @for (childItem of item.subList; track childItem.id) {
                <ng-container
                  [ngTemplateOutlet]="rowTemplateRef"
                  [ngTemplateOutletContext]="{ item: childItem, isChild: true }"
                />
              }
            </tbody>
          </table>
        </td>
      </tr>
    }
  }
</ng-template>
