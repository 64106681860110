import { Injectable } from '@angular/core';
import {
  Airline,
  GetCountriesResponse,
  GetFlightDateOptionsResponse,
  Hotel,
  SearchTourPackagesResponse
} from '@appTypes/api.types';
import { DistrictsTreeItem, ITourPackage } from '@appTypes/redefined-types';
import { IS_MOBILE, deepClean, hotelPackageExtraParamsProcess } from '@core/misc/misc.utils';
import { DataLoadStatusModel, RequestStateModel } from '@core/store/store.models';
import { RequestStateParams } from '@core/store/store.types';
import { SVGIcon, SVG_ICONS } from '@data/svg-icons.data';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { isEmpty } from 'lodash-es';
import { catchError, tap, throwError } from 'rxjs';
import { ToursCommonService } from '../services/tours-common.service';
import {
  toursGetArrivalDistricts,
  toursGetAvailableAirlines,
  toursGetDestinationCountries,
  toursGetHotels,
  toursLoadFlightDateOptions,
  toursOpenDetails,
  toursReset,
  toursResetSearchResult,
  toursSearchTourPackages
} from './tours.actions';
import { ToursFlightAvailabilityModel, ToursSearchFormDataModel, ToursStateModel } from './tours.model';
import { AppPersistedState } from 'src/app/state/app.persisted.state';

const defaults = new ToursStateModel();

@State<ToursStateModel>({
  name: 'tours',
  defaults
})
@Injectable()
export class ToursState {
  constructor(private common: ToursCommonService) {}
  @Action(toursReset)
  toursReset({ setState }: StateContext<ToursStateModel>) {
    return setState(defaults);
  }
  @Action(toursSearchTourPackages)
  toursSearchTourPackages(
    { getState, patchState, setState }: StateContext<ToursStateModel>,
    { paging }: toursSearchTourPackages
  ) {
    const {
      searchResults: { loadState },
      searchForm
    } = getState();
    if (loadState.status === 'loading') return;

    if (!searchForm.model) return;

    const payload = deepClean(searchForm.model);
    if (isEmpty(payload)) return;

    if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.show();

    if (!paging?.pagingId)
      patchState({
        searchResults: new RequestStateModel<SearchTourPackagesResponse>(new DataLoadStatusModel('loading'))
      });
    else
      setState(
        patch<ToursStateModel>({
          searchResults: patch<RequestStateModel<SearchTourPackagesResponse>>({
            loadState: new DataLoadStatusModel('loading')
          })
        })
      );
    const pageRowCount = paging?.pageRowCount ? paging.pageRowCount : 25;
    const searchCurrency = this.common.storeService.selectSnapshot(AppPersistedState.activedCurrency);
    return this.common.api
      .searchTourPackages({ ...payload, ...paging, pageRowCount: pageRowCount, searchCurrency })
      .pipe(
        tap((response) => {
          patchState({
            searchResults:
              !paging?.pagingId && !response.packages?.length
                ? new RequestStateModel<SearchTourPackagesResponse>(
                    new DataLoadStatusModel('error', 'Data not found to show. try searching with different parameters')
                  )
                : new RequestStateModel<SearchTourPackagesResponse>({
                    ...response,
                    packages: response.packages?.map((e) => ({
                      ...e,
                      hotel: e.hotel
                        ? hotelPackageExtraParamsProcess({ childrenAges: payload.childrenAges ?? [] }, [e.hotel])[0]
                        : undefined
                    }))
                  })
          });
          if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.hide();
        }),
        catchError((e) => {
          if (!paging?.pagingId)
            patchState({
              searchResults: new RequestStateModel<SearchTourPackagesResponse>(
                new DataLoadStatusModel('error', e?.displayErrorMessage)
              )
            });
          else
            setState(
              patch<ToursStateModel>({
                searchResults: patch<RequestStateModel<SearchTourPackagesResponse>>({
                  loadState: new DataLoadStatusModel('error', e?.displayErrorMessage)
                })
              })
            );
          if (!IS_MOBILE) !paging?.pagingId && this.common.loadingWindow.hide();
          return throwError(() => e);
        })
      );
  }

  @Action(toursResetSearchResult)
  toursResetSearchResult({ patchState }: StateContext<ToursStateModel>) {
    patchState({
      searchResults: new RequestStateModel<SearchTourPackagesResponse>()
    });
  }
  @Action(toursGetAvailableAirlines, { cancelUncompleted: true })
  toursGetAvailableAirlines(
    { getState, setState }: StateContext<ToursStateModel>,
    { refresh, params }: toursGetAvailableAirlines
  ) {
    const {
      searchFormData: { availableAirlines }
    } = getState();

    if (availableAirlines.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<Airline[]>) => {
      setState(
        patch<ToursStateModel>({
          searchFormData: patch<ToursSearchFormDataModel>({ availableAirlines: new RequestStateModel(stateParams) })
        })
      );
    };

    params = deepClean(params);

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getAvailableAirlines(params).pipe(
      tap((response) => updateState(response)),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }

  @Action(toursGetArrivalDistricts, { cancelUncompleted: true })
  toursGetArrivalDistricts(
    { getState, setState }: StateContext<ToursStateModel>,
    { refresh, params }: toursGetArrivalDistricts
  ) {
    const {
      searchFormData: { arrivalDistricts }
    } = getState();
    if (arrivalDistricts.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<DistrictsTreeItem[]>) => {
      setState(
        patch<ToursStateModel>({
          searchFormData: patch<ToursSearchFormDataModel>({ arrivalDistricts: new RequestStateModel(stateParams) })
        })
      );
    };

    params = deepClean(params);

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getArrivalDistricts(params).pipe(
      tap((response) => updateState(response)),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }

  @Action(toursGetHotels, { cancelUncompleted: true })
  toursGetHotels({ getState, setState }: StateContext<ToursStateModel>, { refresh, params }: toursGetHotels) {
    const {
      searchFormData: { hotels }
    } = getState();

    if (hotels.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<Hotel[]>) => {
      setState(
        patch<ToursStateModel>({
          searchFormData: patch<ToursSearchFormDataModel>({ hotels: new RequestStateModel(stateParams) })
        })
      );
    };

    params = deepClean(params);

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getHotels(params).pipe(
      tap((response) => {
        response.sort((a, b) => {
          const hotelNameA = a?.hotelName?.toUpperCase();
          const hotelNameB = b?.hotelName?.toUpperCase();
          return hotelNameA && hotelNameB ? hotelNameA.localeCompare(hotelNameB) : 0;
        });
        updateState(response);
      }),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }

  @Action(toursLoadFlightDateOptions)
  toursLoadFlightDateOptions(
    { getState, setState }: StateContext<ToursStateModel>,
    { params, identifier }: toursLoadFlightDateOptions
  ) {
    const {
      searchFormData: { flightAvailabilities }
    } = getState();

    const exists = flightAvailabilities.some((e) => e.identifier === identifier);
    if (exists) return;

    const updateState = (data: RequestStateModel<GetFlightDateOptionsResponse>) => {
      setState(
        patch<ToursStateModel>({
          searchFormData: patch<ToursSearchFormDataModel>({
            flightAvailabilities: insertItem<ToursFlightAvailabilityModel>({ data, identifier })
          })
        })
      );
    };

    return this.common.catalogueApi.getFlightDateOptions(params).pipe(
      tap((response) => {
        updateState({
          response,
          loadState: new DataLoadStatusModel('completed')
        });
      }),
      catchError((e) => {
        updateState({
          response: null,
          loadState: new DataLoadStatusModel(
            'error',
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        });
        return throwError(() => e);
      })
    );
  }

  @Action(toursGetDestinationCountries, { cancelUncompleted: true })
  toursGetDestinationCountries(
    { getState, setState }: StateContext<ToursStateModel>,
    { refresh }: toursGetDestinationCountries
  ) {
    const {
      searchFormData: { destinationCountries }
    } = getState();

    if (destinationCountries.loadState.status === 'completed' && !refresh) return;

    const updateState = (stateParams: RequestStateParams<GetCountriesResponse>) => {
      setState(
        patch<ToursStateModel>({
          searchFormData: patch<ToursSearchFormDataModel>({ destinationCountries: new RequestStateModel(stateParams) })
        })
      );
    };

    updateState(new DataLoadStatusModel('loading'));

    return this.common.catalogueApi.getDestinationCountries({ onlyHotels: false }).pipe(
      tap((response) => updateState(response)),
      catchError((e) => {
        updateState(
          new DataLoadStatusModel(
            'error',
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you"
          )
        );
        return throwError(() => e);
      })
    );
  }
  @Action(toursOpenDetails, { cancelUncompleted: true })
  toursOpenDetails(ctx: StateContext<ToursStateModel>, { data }: toursOpenDetails) {
    if (!data.id) return;
    this.common.openDetails(data);
    //@note = needed for the mobile version, will be removed in the future
  }
  /* Selectors */

  @Selector([ToursState])
  static availableAirlines({ searchFormData: { availableAirlines } }: ToursStateModel) {
    return availableAirlines;
  }

  static findAirlineName(code: string) {
    return createSelector([ToursState], ({ searchFormData: { availableAirlines } }: ToursStateModel): string | null => {
      if (!availableAirlines?.response?.length) return null;
      return availableAirlines?.response?.find((c) => c.airlineCode === code)?.airlineName ?? null;
    });
  }
  @Selector([ToursState])
  static availableAirlinesWithIcons({ searchFormData: { availableAirlines } }: ToursStateModel) {
    return {
      ...availableAirlines,
      response: availableAirlines.response?.length
        ? availableAirlines.response.map((a) => {
            const icon = a?.airlineCode ? (`airline-logo-${a.airlineCode.toLowerCase()}` as SVGIcon) : null;
            return { ...a, icon: icon ? (SVG_ICONS?.[icon] ? icon : 'airplane-outline-2') : 'airplane-outline-2' };
          })
        : null
    };
  }

  @Selector([ToursState])
  static destinationCountries({ searchFormData: { destinationCountries } }: ToursStateModel) {
    return destinationCountries;
  }

  @Selector([ToursState])
  static destinationCountriesWithFlagIcon({ searchFormData: { destinationCountries } }: ToursStateModel) {
    return {
      ...destinationCountries,
      response: destinationCountries.response?.countries?.length
        ? destinationCountries.response.countries.map((c) => {
            const icon = c?.countryCode ? (`flag-${c.countryCode.toLowerCase()}` as SVGIcon) : null;
            return {
              ...c,
              icon: icon ? (SVG_ICONS?.[icon] ? icon : 'map-marker') : 'map-marker'
            };
          })
        : null
    };
  }

  static findDestinationCountryName(code: string) {
    return createSelector(
      [ToursState],
      ({ searchFormData: { destinationCountries } }: ToursStateModel): string | null => {
        if (!destinationCountries.response?.countries?.length) return null;
        return destinationCountries.response?.countries.find((c) => c.countryCode === code)?.countryName ?? null;
      }
    );
  }

  static flightAvailabilityByParams(identifier: string) {
    return createSelector([ToursState], ({ searchFormData: { flightAvailabilities } }: ToursStateModel) => {
      const flightAvailability = flightAvailabilities.find((e) => e.identifier === identifier)?.data;
      if (flightAvailability) return flightAvailability;
      else return new RequestStateModel<GetFlightDateOptionsResponse>(new DataLoadStatusModel('loading'));
    });
  }

  static getHotelExtraParams(id: string) {
    return createSelector([ToursState], ({ searchResults: { response } }: ToursStateModel) => {
      return (response?.packages as ITourPackage[])?.find((e) => e.id === id)?.hotel?._extraParams;
    });
  }

  @Selector([ToursState])
  static arrivalDistricts({ searchFormData: { arrivalDistricts } }: ToursStateModel) {
    return arrivalDistricts;
  }
  @Selector([ToursState])
  static hotels({ searchFormData: { hotels } }: ToursStateModel) {
    return hotels;
  }

  @Selector([ToursState])
  static isResult({ searchResults }: ToursStateModel) {
    return Boolean(searchResults?.response?.packages?.length);
  }

  @Selector([ToursState])
  static searchLoadStatus({ searchResults: { loadState } }: ToursStateModel): DataLoadStatusModel {
    return loadState;
  }

  @Selector([ToursState])
  static searchResults({ searchResults }: ToursStateModel) {
    return searchResults;
  }
  @Selector([ToursState])
  static searchFormData({ searchForm: { model } }: ToursStateModel) {
    return model;
  }

  @Selector([ToursState])
  static selectedTourOtherPackages({ selectedTourOtherPackages }: ToursStateModel) {
    return { ...selectedTourOtherPackages, response: selectedTourOtherPackages.response?.packages ?? [] };
  }
}
