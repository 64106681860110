import { ChangeDetectorRef, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  Baggage,
  CalcTourPackageRequest,
  CalcTourPackageResponse,
  FlightPassengerOut,
  HotelExtraServiceBase,
  HotelExtraServicePriceBase,
  RefreshPriceResultEnum,
  TourPackage
} from '@appTypes/api.types';
import { ITourPackage } from '@appTypes/redefined-types';
import { ApiErrorResponse } from '@core/interceptors/api.interceptor';
import { StoreService } from '@core/store/store.service';
import { SVGIcon, SVG_ICONS } from '@data/svg-icons.data';
import { ToursApiService } from '@pages/tours-page/services/tours-api.service';
import { ToursSearchFormService } from '@pages/tours-page/services/tours-search-form.service';
import { ToursState } from '@pages/tours-page/state/tours.state';
import { cartAddItem } from '@shared/modules/cart/state/cart.actions';
import { ConfirmService } from '@shared/modules/confirm/confirm.service';
import { ConfirmParams, ConfirmType } from '@shared/modules/confirm/confirm.types';
import { DYNAMIC_MODAL_DATA_TOKEN } from '@shared/modules/dynamic-modal/dynamic-modal.const';
import { DynamicModalRef } from '@shared/modules/dynamic-modal/dynamic-modal.types';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-tour-details-desktop',
  templateUrl: './tour-details-desktop.component.html',
  styleUrl: './tour-details-desktop.component.scss'
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourDetailsDesktopComponent {
  @ViewChild('modalFooterTemplateRef') public modalFooterTemplateRef!: TemplateRef<unknown>;
  private modalRef!: DynamicModalRef<TourDetailsDesktopComponent>;

  private readonly svgIcons = SVG_ICONS;
  public flightSelectorTemplateTypedToken!: { option: TourPackage; selectView?: boolean };
  public packageTypedToken!: TourPackage;
  public bagageTypedToken!: Baggage;
  public bagageSelectorTemplateTypedToken!: { option: Baggage };
  public selectedTourOtherPackages$ = this.storeService.select(ToursState.selectedTourOtherPackages);

  public package: TourPackage | null = null; // = this.data;
  public includedInPackagePrice: boolean | undefined = this.package?.insurancePrice?.includedInPackagePrice;
  public extraServicePriceTypedToken!: HotelExtraServicePriceBase;
  public extraServiceSelectorTemplateTypedToken!: { option: HotelExtraServicePriceBase };
  public passengers: FlightPassengerOut[] | null = null;
  public altPackages: TourPackage[] | null = null;

  public getAirlineIconFn = (airlineId: string): SVGIcon => {
    const icon = `airline-logo-${airlineId?.toLowerCase()}` as SVGIcon;
    return this.svgIcons?.[icon] ? icon : 'airplane-outline-2';
  };
  public getSelectedExtraServiceDataFn = ({
    extraServiceCode,
    includedPriceCode
  }: Pick<HotelExtraServiceBase, 'extraServiceCode' | 'includedPriceCode'>) =>
    this.package?.extraServices
      ?.find((e) => e.extraServiceCode === extraServiceCode)
      ?.prices?.find((e) => e.extraServicePriceCode === includedPriceCode);

  public selectedFlightValueControl = new FormControl<string | null>(null);
  public isLoading = true;
  public allowAddToCart = true;
  public readonly formGroup = new FormGroup({
    passengersForwardBaggages: new FormArray<
      FormGroup<{
        id: FormControl<string | null>;
        baggageCode: FormControl<string | null>;
      }>
    >([]),
    passengersBackwardBaggages: new FormArray<
      FormGroup<{
        id: FormControl<string | null>;
        baggageCode: FormControl<string | null>;
      }>
    >([])
  });
  public get passengersForwardBaggagesFormArray() {
    return this.formGroup.controls.passengersForwardBaggages;
  }
  public get passengersBackwardBaggagesFormArray() {
    return this.formGroup.controls.passengersBackwardBaggages;
  }

  public get hotelData() {
    return { ...this.package?.hotel };
  }
  public getHotelExtraParams = (id: string) => {
    return this.storeService.selectSnapshot(ToursState.getHotelExtraParams(id));
  };

  constructor(
    @Inject(DYNAMIC_MODAL_DATA_TOKEN) public data: ITourPackage,
    private storeService: StoreService,
    private toursApiService: ToursApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private searchService: ToursSearchFormService,
    private confirmService: ConfirmService
  ) {
    this.calcPackage(
      {
        packageId: this.data?.id,
        includeInsurance: this.data.insurancePrice?.includedInPackagePrice
      },
      true
    );
  }

  getFlightPassengersPayload(baggageCode?: string | null) {
    return this.passengers?.map((p, i) => {
      return {
        id: p.id,
        selectedForwardBaggageCode: baggageCode || this.passengersForwardBaggagesFormArray.at(i)?.value.baggageCode,
        selectedBackwardBaggageCode: baggageCode || this.passengersBackwardBaggagesFormArray.at(i)?.value.baggageCode
      };
    });
  }
  public onExtraServiceSelect(extraServiceCode: string, data: HotelExtraServicePriceBase | null) {
    if (!this.package?.extraServices) return;

    const extraServicePriceCodes: string[] = data?.extraServicePriceCode ? [data.extraServicePriceCode] : [];

    //take `PriceCodes` codes from other `extraServices`
    for (const iterator of this.package.extraServices)
      if (extraServiceCode !== iterator.extraServiceCode && iterator.includedPriceCode)
        extraServicePriceCodes.push(iterator.includedPriceCode);

    const flightPassengers = this.getFlightPassengersPayload();
    this.formGroup.disable();
    this.isLoading = true;
    this.calcPackage({
      flightPassengers,
      packageId: this.package?.id,
      extraServicePriceCodes,
      includeInsurance: this.includedInPackagePrice
    });
  }
  public onOtherFlightSelect(data: TourPackage) {
    this.isLoading = true;
    this.calcPackage({
      packageId: data.id,
      includeInsurance: data.insurancePrice?.includedInPackagePrice
    });
  }

  public onchange(e: boolean) {
    if (!this.package) return;
    const flightPassengers = this.getFlightPassengersPayload();
    this.formGroup.disable();
    this.calcPackage({
      flightPassengers,
      packageId: this.package.id,
      includeInsurance: e
    });
  }

  onBagageChange(bagage: Baggage) {
    if (this.package?.flight?.sameBaggageForAll) {
      const selectedBagages = this.passengers?.map((p) => ({ id: p.id, baggageCode: bagage.baggageCode }));
      this.formGroup.patchValue({
        passengersForwardBaggages: selectedBagages,
        passengersBackwardBaggages: selectedBagages
      });
    }
    if (!this.isLoading) {
      this.isLoading = true;
      this.calcPassengersBagage();
    }
  }

  onCheckBagage({ checked }: MatCheckboxChange) {
    this.isLoading = true;
    this.calcPassengersBagage(checked ? this.passengers?.[0].forwardBaggages?.[0].baggageCode || null : null);
  }

  calcPassengersBagage(baggageCode?: string | null) {
    const flightPassengers = this.getFlightPassengersPayload(baggageCode);
    this.formGroup.disable();
    this.calcPackage({ flightPassengers, packageId: this.package?.id, includeInsurance: this.includedInPackagePrice });
  }

  private showErrorModal(message: string, params: ConfirmParams | ConfirmType, refreshResults = false) {
    this.confirmService.confirm(message, params).subscribe(() => {
      if (refreshResults) {
        this.modalRef.close();
        this.searchService.onSubmit();
      }
    });
  }

  private handleRefreshPriceResult(response: CalcTourPackageResponse, checkPrice = false) {
    switch (response?.refreshPriceResult) {
      case RefreshPriceResultEnum.HotelPriceNoMoreAvailable:
        this.showErrorModal('Hotel price no more available', 'error', true);
        break;
      case RefreshPriceResultEnum.FlightPriceNoMoreAvailable:
        this.showErrorModal('Flight price no more available', 'error', true);
        break;
      default:
        this.updatePackage(response);
        break;
    }

    if (response?.refreshPriceResult === RefreshPriceResultEnum.Success && checkPrice) {
      const totalPrice = response?.package?.totalPrice;
      const existingPrice = this.data.totalPrice;

      if (totalPrice && existingPrice && existingPrice > totalPrice) {
        this.confirmService.confirm('Price has been reduced', 'info');
      } else if (totalPrice && existingPrice && existingPrice < totalPrice) {
        this.confirmService.confirm('Price has been increased', 'info');
      }
    }
  }
  fillBagageArray(bagageCode?: string | null) {
    this.passengersForwardBaggagesFormArray.clear();
    this.passengersBackwardBaggagesFormArray.clear();
    if (!this.passengers?.length) return;
    this.passengers.forEach((p) => {
      this.passengersForwardBaggagesFormArray.push(
        new FormGroup({
          id: new FormControl<string | null>(p.id || null),
          baggageCode: new FormControl<string | null>(bagageCode || p.selectedForwardBaggageCode || null)
        })
      );
    });
    this.passengers.forEach((p) => {
      this.passengersBackwardBaggagesFormArray.push(
        new FormGroup({
          id: new FormControl<string | null>(p.id || null),
          baggageCode: new FormControl<string | null>(p.selectedBackwardBaggageCode || null)
        })
      );
    });
  }

  updatePackage(data: CalcTourPackageResponse) {
    this.passengers = data.package?.flight?.passengers || null;
    this.altPackages = data.altPackages || null;

    if (
      this.passengersForwardBaggagesFormArray.value.length !== data.package?.flight?.passengers?.length &&
      this.package?.id !== data.package?.id
    ) {
      this.fillBagageArray();
    }
    const _extraParams = this.getHotelExtraParams(data?.package?.id as string);
    const packageWithExtraParams = { ...data.package, hotel: { ...data?.package?.hotel, _extraParams: _extraParams } };
    this.package = packageWithExtraParams || null;
    this.includedInPackagePrice = data.package?.insurancePrice?.includedInPackagePrice;

    this.isLoading = false;

    this.changeDetectorRef.markForCheck();
  }

  calcPackage(payload: CalcTourPackageRequest, checkPrice = false) {
    this.toursApiService
      .calcPackage(payload)
      .pipe(
        catchError((e: ApiErrorResponse) => {
          this.formGroup.enable();
          this.isLoading = false;
          this.package = null;
          this.showErrorModal(
            e?.displayErrorMessage ??
              "It seems that something has gone wrong. Don't worry, our support team is ready to help you",
            { type: 'error', activityId: e.activityId }
          );
          return throwError(() => e);
        })
      )
      .subscribe((data) => {
        this.formGroup.enable();
        if (data.error) {
          this.showErrorModal(data.errorDescription || '', 'error');
          return;
        }
        this.handleRefreshPriceResult(data, checkPrice);
      });
  }

  public onAddCart() {
    if (!this.package?.totalPrice || !this.package.priceCurrency) return;
    this.storeService.dispatch(
      new cartAddItem({
        type: 'tour',
        data: this.package,
        price: this.package.totalPrice,
        priceCurrency: this.package.priceCurrency
      })
    );
    this.modalRef.close();
  }
}
